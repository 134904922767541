<template>
    <div>
        <v-list-item class="py-2">
            <v-list-item-content>
                <v-list-item-title class="text-h5">
                    <div>{{ title }}</div>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>
            <!-- add -->
            <v-list-item link v-on:click="createItem">
                <v-list-item-icon>
                    <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ createItemText }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- list items -->
            <v-list-item-group v-model="selectedItemIndex" color="blue">
                <v-list-item v-for="item in items" :key="item.id" v-on:click="selectItem(item.id)">
                    <v-list-item-content>
                        <v-list-item-title>{{ item[labelField] | labelText }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>

        </v-list>

    </div>
</template>

<script>
import mitt from "mitt";

window.mitt = window.mitt || new mitt();
export default {
    name: "CustomLayoutItemSelector",
    props: ["title", "createItemText", "items", "labelField"],
    data: () => ({
        selectedItemIndex: 0
    }),
    filters: {
        labelText: function(value) {
            if (value === undefined) {
                return "LABEL FIELD IS UNDEFINED";
            }
            if (value === null) {
                return "LABEL FIELD IS NULL";
            }
            if (value.length === 0) {
                return "<unbenannt>";
            }
            return value;
        }
    },
    watch: {
        items: function() {
            this.selectedItemIndex = undefined;
            this.selectItem();
        }
    },
    methods: {
        createItem: function() {
            window.mitt.emit("customLayoutCreateItem-" + this.title);
        },
        selectItem: function(itemId) {
            this.$nextTick(function() {
                const emittedId = (this.selectedItemIndex !== undefined) ? itemId : undefined;
                window.mitt.emit("customLayoutSelectItem-" + this.title, emittedId);
            });
        }
    }
};
</script>

<style scoped>

</style>