<template>
  <div class="custom-layout-container">
    <div class="custom-layout-side">
      <div class="custom-layout-side-content">
        <slot name="side"></slot>
      </div>
      <div class="custom-layout-side-border"></div>
    </div>
    <div class="custom-layout-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomLayout"
};
</script>

<style scoped>

.custom-layout-container {
  display: flex;
}

.custom-layout-side {
  height: 100vh;
  top: 64px;
  max-height: calc(100% - 64px);
  transform: translateX(0%);
  width: 256px;
  background-color: white;
  position: fixed;
}

.custom-layout-side-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-layout-side-border {
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}

.custom-layout-main {
  padding-left: 256px;
  flex-grow: 1;
}

</style>