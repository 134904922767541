<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar color="primary" dark flat tile>
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tabs-slider color="#FFCB62"></v-tabs-slider>
                    <v-tab>
                        <v-icon left class="fix-button-icon-size">mdi-account</v-icon>
                        Details
                    </v-tab>
                    <v-tab disabled>
                        <v-icon left class="fix-button-icon-size">mdi-email</v-icon>
                        Kontakt
                    </v-tab>
                </v-tabs>
                <v-btn class2="ml-4 mr-1" icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-0 card-text">
            <v-tabs-items v-model="tab">
                <v-tab-item class="px-6 py-2">

                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col class="col-12">
                                <div class="text-center text-h6 mt-2 mb-4 text-md-h4">{{ contact.firstName + " " + contact.lastName }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="col-4">
                                <v-text-field v-model="contact.salutation" label="Anrede" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.title" label="Titel" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.firstName" label="Vorname" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.lastName" label="Nachname" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.email" label="E-Mail" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.mobile" label="Mobil" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.phone" label="Telefon" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.birthday" label="Geburtstag" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.street" label="Straße" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.houseNumber" label="Hausnummer" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.zipCode" label="PLZ" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.city" label="Stadt" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.country" label="Land" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.company" label="Firma" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.department" label="Abteilung" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.position" label="Position" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.website" label="Website" hide-details @input="change"></v-text-field>
                                <v-text-field v-model="contact.notes" label="Notizen" hide-details @input="change"></v-text-field>
                            </v-col>
                            <v-col class="col-4">
                                <div class="text-h6">Kontaktlisten:</div>
                                <v-select v-model="assignedContactLists" :items="allContactLists" item-value="id" item-text="name" chips placeholder="(keine)" multiple
                                          @input="assignedContactListsChanged">
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeAssignedContactList(item)">
                                            <span>{{ item.name }}</span>
                                        </v-chip>
                                    </template>
                                </v-select>
                                <v-btn color="red" class="mt-2" @click="deleteContact" dark outlined>
                                    <v-icon left>mdi-delete</v-icon>
                                    Kontakt löschen
                                </v-btn>
                            </v-col>
                            <v-col class="col-4">

                            </v-col>
                        </v-row>

                    </v-container>


                </v-tab-item>
                <v-tab-item>
                    <MemberDetails :user-id="55"></MemberDetails>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

    </v-card>
</template>


<script>
import mitt from "mitt";
import _ from "lodash";
import MemberDetails from "@/components/MemberDetails.vue";

window.mitt = window.mitt || new mitt();

/**
 * Notes: For some reason, the dialog in ContactLists does not create or mount the ContactDetails component until the dialog is opened.
 * This means that we assume that the contactProp is already set when the component is mounted.
 * But this also means that the prop watcher will only fire the second time the dialog is opened.
 */
export default {
    name: "ContactDetails",
    components: { MemberDetails },
    props: {
        contactProp: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        contact: {},
        allContactLists: [],
        assignedContactLists: [],
        contactDetailsChanged: false,
        tab: 0
    }),
    mounted() {
        this.initializeWithContact(this.contactProp);
        window.mitt.on("contact-details.open", this.initializeWithContact);
    },
    methods: {
        initializeWithContact(contact) {
            this.allContactLists = [];
            this.assignedContactLists = [];
            this.contact = JSON.parse(JSON.stringify(contact));
            this.contactDetailsChanged = false;
            this.$store.dispatch("getRequestPromise", `contacts/${contact.id}/additional-info`)
                .then(response => {
                    this.allContactLists = response.allContactLists;
                    this.assignedContactLists = response.assignedContactLists;
                });
        },
        change() {
            this.contactDetailsChanged = true;
            this.schedulePush(this.contact);
        },
        schedulePush: _.debounce(function(contact) {
            this.pushChanges(contact);
        }, 200),
        pushChanges(contact) {
            this.$store.dispatch("putRequestPromise", [`contacts/${contact.id}`, contact]);
        },
        assignedContactListsChanged(contactListIds) {
            this.contactDetailsChanged = true;
            this.$store.dispatch("putRequestPromise", [`contacts/${this.contact.id}/contact-lists`, contactListIds]);
        },
        removeAssignedContactList(contactList) {
            this.assignedContactLists = this.assignedContactLists.filter(assignedContactList => assignedContactList.id !== contactList.id);
            this.assignedContactListsChanged(this.assignedContactLists.map(contactList => contactList.id));
        },
        closeDialog() {
            if (!this.contactDetailsChanged && this.contact.newlyCreated) {
                this.$store.dispatch("deleteRequestPromise", [`contacts/${this.contact.id}`]);
            }
            window.mitt.emit("contact-details.close", this.contactDetailsChanged);
        },
        deleteContact() {
            this.contactDetailsChanged = true;
            this.$store.dispatch("deleteRequestPromise", [`contacts/${this.contact.id}`])
                .then(() => {
                    this.closeDialog();
                });
        }
    },
    beforeDestroy() {
        window.mitt.off("contact-details.open", this.initializeWithContact);
    }
};

</script>

<style scoped>
.card-text {
    color: revert !important;
    font-size: revert !important;
    font-weight: revert !important;
    line-height: revert !important;
    letter-spacing: revert !important;
}
</style>