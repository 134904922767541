<template>
    <div>
        <div :class="editing ? 'd-none' : 'd-flex'" class="align-center" style="height: 40px">
            <div class="text-h5" v-text="displayValue"></div>
            <v-btn v-if="!readonly" class="ml-2" color="" icon :ripple="false" v-on:click="startEditing">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </div>
        <div :class="editing ? 'd-flex' : 'd-none'" class="align-center">
            <v-text-field v-model="newValue" class="text-h5" hide-details="auto" outlined dense
                          ref="tfNewValue" @blur="leaveTextField" @keydown="keyDown"></v-text-field>
            <v-btn class="ml-2" color="green" outlined :ripple="false" v-on:click="save">
                <v-icon left>mdi-check</v-icon>
                Save
            </v-btn>
            <v-btn class="ml-2" color="red" outlined :ripple="false" v-on:click="stopEditing">
                <v-icon left>mdi-close</v-icon>
                Cancel
            </v-btn>
        </div>
    </div>
</template>

<script>

export default {
    name: "EditLabel",
    props: ["value", "changeCallback", "readonly"],
    data: () => ({
        editing: false,
        newValue: undefined
    }),
    computed: {
        displayValue: function() {
            if (this.value === undefined || this.value === null || this.value.length === 0) {
                return "<unbenannt>";
            } else {
                return this.value;
            }
        }
    },
    mounted() {

    },
    methods: {
        startEditing: function() {
            this.newValue = this.value;
            this.editing = true;
            setTimeout(function() {
                this.$refs["tfNewValue"].focus();
            }.bind(this), 100);
        },
        leaveTextField: function() {
            setTimeout(function() {
                this.stopEditing();
            }.bind(this), 300);
        },
        stopEditing: function() {
            this.editing = false;
        },
        save: function() {
            this.stopEditing();
            this.changeCallback(this.newValue);
        },
        keyDown: function(event) {
            if (event.key === "Enter") {
                this.save();
            }
        }
    }
};
</script>

<style scoped>

</style>