<template>
    <v-main>
        <CustomLayout>
            <template slot="side">
                <CustomLayoutItemSelector title="Kontaktlisten" create-item-text="Neue Kontaktliste"
                                          :items="contactLists" label-field="name"></CustomLayoutItemSelector>
            </template>

            <!-- main area -->
            <v-container class="pt-4 d-none d-md-block">
                <div v-for="contactList in contactLists" :key="contactList.id"
                     v-if="openedContactLists.includes(contactList.id)"
                     v-show="contactList.id === selectedContactListId" class="">

                    <div class="d-flex justify-space-between align-center ml-2 mb-4">
                        <EditLabel class="" :value="contactList.name" :change-callback="nameChanged.bind(null, contactList.id)"
                                   :readonly="contactList['predefined']"></EditLabel>
                        <v-btn outlined small color="primary" @click="createContact" :loading="contactCreationInProgress">
                            <v-icon left>mdi-plus</v-icon>
                            Neuer Kontakt
                        </v-btn>
                    </div>

                    <ContactList class="mt2-3" :contact-list-id="contactList.id"></ContactList>

                </div>
            </v-container>

        </CustomLayout>

        <v-dialog :scrollable="true" v-model="contactDetailsDialogOpen" fullscreen transition="dialog-bottom-transition">
            <ContactDetails :contactProp="contactDetailsProp"></ContactDetails>
        </v-dialog>

    </v-main>
</template>

<script>
import CustomLayout from "@/components/reusable/CustomLayout";
import CustomLayoutItemSelector from "@/components/reusable/CustomLayoutItemSelector";
import Vue from "vue";
import ContactList from "@/components/ContactList.vue";
import ContactDetails from "@/components/ContactDetails.vue";
import mitt from "mitt";
import EditLabel from "@/components/reusable/EditLabel.vue";

window.mitt = window.mitt || new mitt();

export default {
    name: "ContactLists",
    components: { EditLabel, ContactDetails, ContactList, CustomLayoutItemSelector, CustomLayout },
    props: [],
    data: () => ({
        contactLists: [],
        openedContactLists: [],
        selectedContactListId: undefined,
        contactDetailsDialogOpen: false,
        contactDetailsProp: {},
        contactCreationInProgress: false
    }),
    computed: {},
    watch: {},
    mounted() {
        window.mitt.on("customLayoutCreateItem-Kontaktlisten", this.createContactList);
        window.mitt.on("customLayoutSelectItem-Kontaktlisten", this.selectContactList);
        window.mitt.on("contact-details.open", this.openContactDetails);
        window.mitt.on("contact-details.close", this.closeContactDetails);
        this.fetchContactLists();
    },
    methods: {
        fetchContactLists() {
            return new Promise((resolve, reject) => {
                this.$store.dispatch("getRequestPromise", "contact-lists")
                    .then((contactLists) => {
                        this.contactLists = contactLists;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        createContactList: function() {
            this.$store.dispatch("postRequestPromise", ["contact-lists/new"])
                .then(() => {
                    this.fetchContactLists();
                });
        },
        selectContactList: function(id) {
            this.openedContactLists.push(id);
            this.selectedContactListId = id;
        },
        nameChanged(contactListId, newName) {
            const contactList = this.contactLists.find(item => item.id === contactListId);
            contactList.name = newName;
            this.$store.dispatch("putRequestPromise", ["contact-lists/" + contactListId, contactList])
                .then(() => {
                    //this.fetchContactLists();
                });
        },
        openContactDetails(contact) {
            this.contactDetailsProp = contact;
            this.contactDetailsDialogOpen = true;
        },
        closeContactDetails(detailsChanged) {
            this.contactDetailsDialogOpen = false;
            if (detailsChanged) {
                window.mitt.emit("contact-list.refresh", this.selectedContactListId);
            }
        },
        createContact() {
            this.contactCreationInProgress = true;
            this.$store.dispatch("postRequestPromise", ["contacts/in-list/" + this.selectedContactListId, {}])
                .then((contact) => {
                    contact.newlyCreated = true;
                    this.contactCreationInProgress = false;
                    window.mitt.emit("contact-details.open", contact);
                });
        }
    },
    beforeDestroy() {
        window.mitt.off("customLayoutCreateItem-Kontaktlisten", this.createContactList);
        window.mitt.off("customLayoutSelectItem-Kontaktlisten", this.selectContactList);
        window.mitt.off("contact-details.open", this.openContactDetails);
        window.mitt.off("contact-details.close", this.closeContactDetails);
    }
};
</script>

<style scoped>

</style>

<style>

</style>