<template>
    <div>
        <v-card>
            <v-card-title>

                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Suchen" hide-details clearable class="mx-4 mb-2"></v-text-field>

            </v-card-title>
            <v-card-text>

                <v-data-table :items="items" :options.sync="options" :headers="headers" :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                              :search="search" :loading="loading">
                    <template v-slot:item.anyPhone="{ item }">
                        <span>{{ item.phone || item.mobile }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn small outlined color="grey darken-3" @click="openDetails(item)">
                            <v-icon left class="fix-button-icon-size">mdi-open-in-app</v-icon>
                            Öffnen
                        </v-btn>
                    </template>
                </v-data-table>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import mitt from "mitt";
import socialLoginOutdated from "@/components/SocialLoginOutdated.vue";

window.mitt = window.mitt || new mitt();

export default {
    name: "ContactList",
    props: {
        contactListId: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        items: [],
        options: {
            itemsPerPage: 100,
            sortBy: ["id"],
            sortDesc: [true]
        },
        search: "",
        loading: false,

        headers: [
            {
                text: "ID",
                value: "id"
            },
            {
                text: "Vorname",
                align: "start",
                value: "firstName"
            },
            {
                text: "Nachname",
                value: "lastName"
            },
            {
                text: "E-Mail",
                value: "email"
            },
            {
                text: "Telefon",
                value: "anyPhone"
            },
            {
                text: "",
                align: "end",
                value: "actions",
                sortable: false
            }
        ],
        itemsPerPageOptions: [50, 100, 200, 500, 1000]

    }),
    mounted() {
        window.mitt.on("customLayoutSelectItem-Kontaktlisten", this.selectionEvent);
        window.mitt.on("contact-list.refresh", this.refreshEvent);
        this.fetchContacts();
    },
    methods: {
        selectionEvent(contactListId) {
            if (contactListId === this.contactListId) {
                this.fetchContacts();
            }
        },
        fetchContacts() {
            this.loading = true;
            this.$store.dispatch("getRequestPromise", ["contact-lists/" + this.contactListId])
                .then(response => {
                    this.items = response["contacts"];
                    this.loading = false;
                });
        },
        openDetails(contact) {
            window.mitt.emit("contact-details.open", contact);
        },
        refreshEvent(contactListId) {
            if (contactListId === this.contactListId) {
                this.fetchContacts();
            }
        }
    },
    beforeDestroy() {
        window.mitt.off("customLayoutSelectItem-Kontaktlisten", this.selectionEvent);
        window.mitt.off("contact-list.refresh", this.refreshEvent);
    }
};
</script>

<style scoped>

</style>